import React from 'react'
import { List, Modal } from 'semantic-ui-react'

import BaseFooter from '../BaseFooter'
import Disclaimer from '../Disclaimer'

const Footer = () => (
  <BaseFooter>
    <List.Item>
      <List.Content>
        <a
          href="mailto:Denny.Grossman@sgc.ca.gov?subject=[Regional Conservation and Development Planning Tool]"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact program manager
        </a>
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Content>
        <Modal trigger={<a href="#">Disclaimer</a>} closeIcon>
          <Modal.Header>Disclaimer</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <Disclaimer />
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </List.Content>
    </List.Item>
  </BaseFooter>
)

export default Footer
