import React from 'react'
import { OrderedMap } from 'immutable'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Confirm, Icon, Menu, Segment } from 'semantic-ui-react'

import { runAnalysis, updateResults } from '../../actions/analysis'
import { startFilter, downloadFilterSummary, BOUNDARIES } from '../../actions/filter'
import { updateSelectedSites } from '../../actions/inputs'
import { updateGeometriesSource, updateIdentifyTarget, clearShapefiles } from '../../actions/map'
import { updateActiveStep, updateLoadingStatus, updateScreenStepStatus, STEPS } from '../../actions/page'
import LayoutContext from '../../context'
import { LAYER_STYLES } from '../Map'
import AddSites from '../AddSites'
import Analyze from '../Analyze'
import RCA from '../RCA'
import Regions from '../Regions'
import AOI from '../Screen'
import SelectSites from '../SelectSites'

class Content extends React.Component {
  static contextType = LayoutContext

  constructor(props) {
    super(props)

    this.state = {
      showAnalysisReportDialog: false,
    }
  }

  resetSites = () => {
    const selectedSites = OrderedMap({})
    this.context.map.sitesLayer.setStyle(LAYER_STYLES.unselected)
    this.context.map.updateSelectedSitesAttrs(selectedSites)
    this.props.updateSelectedSites(selectedSites)
  }

  updateActiveStep = activeStep => {
    document.querySelector('body').dataset.step = activeStep
    this.props.updateIdentifyTarget([])
    switch (activeStep) {
      case STEPS.REGION:
        this.resetSites()
        this.context.map.overlays['Ecoregions (USDA Section)'].layer.setStyle(feature =>
          !feature.properties.value ? LAYER_STYLES.rcaUnavailable : LAYER_STYLES.rcaAvailable,
        )
        this.context.map.overlays['Watersheds (HUC 4)'].layer.setStyle(feature =>
          !feature.properties.value ? LAYER_STYLES.watersheds : LAYER_STYLES.watershedsRCA,
        )
        break
      case STEPS.RCA:
        this.context.map.overlays['Ecoregions (USDA Section)'].layer.setStyle(LAYER_STYLES.rcaUnavailable)
        this.context.map.overlays['Watersheds (HUC 4)'].layer.setStyle(LAYER_STYLES.rcaUnavailable)
        break
      case STEPS.AOI:
        this.resetSites()
        break
      case STEPS.ADD_SITES:
        this.context.map.customRegionLayer.setStyle(LAYER_STYLES.customRegion)
        break
      case STEPS.SELECT_SITES:
        this.context.map.customRegionLayer.setStyle(LAYER_STYLES.hidden)
        break
    }
    this.props.updateActiveStep(activeStep)
  }

  handleRegionFinish = () => {
    const bounds = this.context.map.customRegionLayer.getBounds()
    if (Object.keys(bounds).length) {
      this.context.map.flyToBounds(bounds)
    }
    this.updateActiveStep(STEPS.RCA)
  }

  handleRCAFinish = nextStep => {
    this.props.updateScreenStepStatus(nextStep === STEPS.ADD_SITES)
    Object.values(BOUNDARIES).forEach(label => {
      if (this.context.map.overlays[label] && this.context.map.overlays[label].isActive) {
        this.context.map.toggleOverlay(label)
      }
    })
    this.updateActiveStep(nextStep)
  }

  handleFilterClick = () => {
    this.context.map.removeAllAreas()
    this.props.updateGeometriesSource('')
    const { filters } = this.props
    if (Object.keys(filters).length) {
      const data = Object.values(filters).reduce((v1, v2) => v1.concat(v2))
      this.props.startFilter(this.context.map, data, this.props.screenMinPolygonSize * 4046.86)
    }
  }

  handleDownloadScreenSummary = () => {
    this.props.updateLoadingStatus(true)
    this.context.handleExtraSidebarVisibility('FilterSummarySidebarTab', 'open')
    const basemapLayer = this.context.map.basemapControl.options.basemaps.find(
      bl => bl._leaflet_id === this.context.map.basemapControl.basemap._leaflet_id,
    )
    const baseLayers = [
      {
        type: 'tileLayer',
        args: [basemapLayer._url, basemapLayer.options],
      },
    ]

    switch (this.props.regionType) {
      case 'ecoregion': {
        const allRegionLayers = this.context.map.overlays['Ecoregions (USDA Section)'].layer.getLayers()
        this.props.regionListIds.forEach(idx => {
          baseLayers.push({
            type: 'geoJSON',
            args: [
              allRegionLayers[idx].toGeoJSON(),
              {
                style: LAYER_STYLES.rcaUnavailable,
              },
            ],
          })
        })
        break
      }
      case 'county': {
        const allRegionLayers = this.context.map.overlays.Counties.layer.getLayers()
        this.props.regionListIds.forEach(idx => {
          baseLayers.push({
            type: 'geoJSON',
            args: [
              allRegionLayers[idx].toGeoJSON(),
              {
                style: LAYER_STYLES.counties,
              },
            ],
          })
        })
        break
      }
      case 'watershed': {
        const allRegionLayers = this.context.map.overlays['Watersheds (HUC 4)'].layer.getLayers()
        this.props.regionListIds.forEach(idx => {
          baseLayers.push({
            type: 'geoJSON',
            args: [
              allRegionLayers[idx].toGeoJSON(),
              {
                style: LAYER_STYLES.watersheds,
              },
            ],
          })
        })
        break
      }
      case 'custom':
        baseLayers.push({
          type: 'geoJSON',
          args: [
            this.context.map.customRegionLayer.toGeoJSON(),
            {
              style: LAYER_STYLES.customRegion,
            },
          ],
        })
        break
    }

    const layer = this.context.map.sitesLayer
    const layerBounds = layer.getBounds()
    const { regionType } = this.props
    const data = {
      region_type: `${regionType.charAt(0).toUpperCase()}${regionType.slice(1)}`,
      region_list: this.props.regionList,
      map_config: {
        width: 700,
        height: 400,
        bounds: [layerBounds._southWest, layerBounds._northEast],
        layers: baseLayers.concat([
          {
            type: 'geoJSON',
            args: [
              layer.toGeoJSON(),
              {
                style: LAYER_STYLES.unselected,
              },
            ],
          },
        ]),
      },
    }
    this.props.downloadFilterSummary(data)
  }

  handleRemoveAllSites = () => {
    this.context.map.customRegionLayer.clearLayers()
    this.props.clearShapefiles()
    this.props.updateGeometriesSource('')
    this.props.updateResults({}, 'replace')
    this.setState({ showDiscardSitesDialog: false })
  }

  handleAddSitesFinish = () => {
    this.context.map.addToSitesLayer(this.context.map.customRegionLayer.toGeoJSON().features)
    this.updateActiveStep(STEPS.SELECT_SITES)
  }

  handleRunAnalysisClick = () => {
    const geoms = this.context.map.prepareSelectedSitesForAnalysis()
    this.props.runAnalysis('meta', geoms)
  }

  render() {
    const { activeStep } = this.props
    let View
    const ActionButtons = []

    switch (activeStep) {
      case STEPS.REGION:
        View = <Regions changeView={this.updateActiveStep} updateActionButtons={this.updateChildActionButtons} />
        ActionButtons.push(
          <Menu.Item key="region" className="borderless">
            <Button
              primary
              content="Next Step"
              disabled={
                this.props.regionType !== 'california' && this.context.map.customRegionLayer.getLayers().length === 0
              }
              onClick={this.handleRegionFinish}
            />
          </Menu.Item>,
        )
        break
      case STEPS.RCA:
        View = <RCA availableRCAs={this.props.availableRCAs} />
        ActionButtons.push(
          <Menu.Item key="rca" className="borderless">
            <Button primary onClick={() => this.updateActiveStep(STEPS.REGION)}>
              <Icon name="angle double left" />
              Back
            </Button>
            <Button.Group>
              <Button primary content="Screen the Landscape" onClick={() => this.handleRCAFinish(STEPS.AOI)} />
              <Button.Or />
              <Button
                primary
                content="Draw or Import Sites for Analysis"
                onClick={() => this.handleRCAFinish(STEPS.ADD_SITES)}
              />
            </Button.Group>
          </Menu.Item>,
        )
        break
      case STEPS.AOI:
        View = <AOI />
        ActionButtons.push(
          <Menu.Item key="screen" className="borderless" style={{ marginLeft: -10 }}>
            <Button
              primary
              onClick={() =>
                this.updateActiveStep(Object.keys(this.props.availableRCAs).length ? STEPS.RCA : STEPS.REGION)
              }
            >
              <Icon name="angle double left" />
              Back
            </Button>
            <Button
              primary
              content="Find Areas"
              disabled={!Object.keys(this.props.filters).length}
              onClick={this.handleFilterClick}
            />
            <Button
              primary
              content="Download Results"
              disabled={!this.context.map.sitesLayer.getLayers().length}
              onClick={this.handleDownloadScreenSummary}
            />
            <Button
              primary
              content="Select Sites for Analysis"
              disabled={!this.context.map.sitesLayer.getLayers().length}
              onClick={() => this.updateActiveStep(STEPS.SELECT_SITES)}
            />
          </Menu.Item>,
        )
        break
      case STEPS.ADD_SITES:
        View = <AddSites />
        ActionButtons.push(
          <Menu.Item key="add_sites" className="borderless">
            <Button
              primary
              onClick={() =>
                this.updateActiveStep(Object.keys(this.props.availableRCAs).length ? STEPS.RCA : STEPS.REGION)
              }
            >
              <Icon name="angle double left" />
              Back
            </Button>
            <Button
              color="red"
              icon="delete"
              content="Remove All Sites"
              disabled={this.context.map.customRegionLayer.getLayers().length === 0}
              onClick={() => this.setState({ showDiscardSitesDialog: true })}
            />
            <Confirm
              open={this.state.showDiscardSitesDialog}
              content="Remove all the existing sites from the map?"
              onCancel={() => this.setState({ showDiscardSitesDialog: false })}
              onConfirm={this.handleRemoveAllSites}
            />
            <Button
              primary
              content="Select Sites for Analysis"
              disabled={!this.context.map.customRegionLayer.getLayers().length}
              onClick={() => this.handleAddSitesFinish()}
            />
          </Menu.Item>,
        )
        break
      case STEPS.SELECT_SITES:
        View = <SelectSites />
        ActionButtons.push(
          <Menu.Item key="select_sites" className="borderless">
            <Button
              primary
              onClick={() => {
                this.context.map.removeAllAreas()
                this.updateActiveStep(this.props.skippedScreen ? STEPS.ADD_SITES : STEPS.AOI)
              }}
            >
              <Icon name="angle double left" />
              Back
            </Button>
            <Button primary disabled={!this.props.hasSelectedSites} onClick={this.handleRunAnalysisClick}>
              Run Analysis
            </Button>
          </Menu.Item>,
        )
        break
      case STEPS.ANALYZE:
        View = (
          <Analyze
            showReportDialog={this.state.showAnalysisReportDialog}
            closeReportDialog={() => this.setState({ showAnalysisReportDialog: false })}
          />
        )
        ActionButtons.push(
          <Menu.Item key="analyze" className="borderless">
            <Button primary onClick={() => this.updateActiveStep(STEPS.SELECT_SITES)}>
              <Icon name="angle double left" />
              Back to Site Selection
            </Button>
            <Button primary onClick={() => this.setState({ showAnalysisReportDialog: true })}>
              Create Analysis Report
            </Button>
          </Menu.Item>,
        )
        break
    }

    return (
      <>
        <Segment basic padded style={{ height: '100%' }}>
          <div style={{ marginBottom: 70 }}>{View}</div>
        </Segment>

        <Menu className="bottom actionBar" size="tiny">
          {ActionButtons}
        </Menu>
      </>
    )
  }
}

Content.propTypes = {
  // redux props
  activeStep: PropTypes.number.isRequired,
  skippedScreen: PropTypes.bool.isRequired,
  regionType: PropTypes.string.isRequired,
  regionList: PropTypes.arrayOf(PropTypes.string).isRequired,
  regionListIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  filters: PropTypes.shape({}).isRequired,
  screenMinPolygonSize: PropTypes.number.isRequired,
  hasSelectedSites: PropTypes.bool.isRequired,
  availableRCAs: PropTypes.shape({}).isRequired,
  // redux actions
  updateLoadingStatus: PropTypes.func.isRequired,
  updateActiveStep: PropTypes.func.isRequired,
  updateScreenStepStatus: PropTypes.func.isRequired,
  updateGeometriesSource: PropTypes.func.isRequired,
  updateSelectedSites: PropTypes.func.isRequired,
  startFilter: PropTypes.func.isRequired,
  downloadFilterSummary: PropTypes.func.isRequired,
  runAnalysis: PropTypes.func.isRequired,
  updateIdentifyTarget: PropTypes.func.isRequired,
  updateResults: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  const rcaNames = state.getIn(['inputs', 'rca', 'regionsTargets'])
  const regionType = state.getIn(['inputs', 'region', 'region'])
  let regionList = []
  let regionListIds = []
  switch (regionType) {
    case 'ecoregion':
      regionList = Object.values(state.getIn(['inputs', 'region', 'ecoregions']).toJS())
      regionListIds = Object.keys(state.getIn(['inputs', 'region', 'ecoregions']).toJS())
      break
    case 'county':
      regionList = Object.values(state.getIn(['inputs', 'region', 'counties']).toJS())
      regionListIds = Object.keys(state.getIn(['inputs', 'region', 'counties']).toJS())
      break
    case 'watershed':
      regionList = Object.values(state.getIn(['inputs', 'region', 'watersheds']).toJS())
      regionListIds = Object.keys(state.getIn(['inputs', 'region', 'watersheds']).toJS())
      break
  }
  return {
    activeStep: state.getIn(['page', 'activeStep']),
    skippedScreen: state.getIn(['page', 'skippedScreen']),
    regionType,
    regionList,
    regionListIds,
    filters: state.getIn(['inputs', 'filter', 'queryParams']).toJS(),
    screenMinPolygonSize: state.getIn(['inputs', 'filter', 'minPolygonSize']),
    hasSelectedSites: state.getIn(['inputs', 'sites', 'selectedSites']).size > 0,
    availableRCAs: state
      .getIn(['config', 'targets'])
      .filter((v, k) => rcaNames.includes(k))
      .toJS(),
  }
}

export default connect(mapStateToProps, {
  updateLoadingStatus,
  updateActiveStep,
  updateScreenStepStatus,
  updateGeometriesSource,
  updateSelectedSites,
  startFilter,
  downloadFilterSummary,
  runAnalysis,
  updateIdentifyTarget,
  updateResults,
  clearShapefiles,
})(Content)
