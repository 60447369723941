import { fromJS, Map } from 'immutable'
import L from 'leaflet'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'

import { composeEnhancer } from './base'
import { loadConservationTargets, loadDatasets } from './actions/config'
import Layout from './containers/Layout'
import RePlan from './containers/RePlan'
import Footer from './containers/RePlan/Footer'
import Header from './containers/RePlan/Header'
import FilterSummary from './containers/Screen/Summary'

import { ACTIONS } from './actions/save'
import account from './reducers/account'
import config from './reducers/config'
import inputs from './reducers/inputs'
import map from './reducers/map'
import page from './reducers/page'
import analysis from './reducers/analysis'

const loadState = (newState, action) => {
  newState.setIn(['page', 'skipHome'], true)
  newState.set('inputs', fromJS(action.state.inputs))
  newState.setIn(['config', 'models'], fromJS(action.state.config.models))
  newState.setIn(['config', 'targets'], fromJS(action.state.config.targets))
  action.state.customRegions.forEach(region => action.map.customRegionLayer.addData(region))
  Object.entries(action.state.config.models).forEach(([ecoregion]) => {
    const layerName = `${ecoregion}_custom`
    const layer = L.tileLayer(
      `tiles/${
        action.state.config.targets[ecoregion][action.state.config.targets[ecoregion].length - 1].map_server
      }/{z}/{x}/{y}.png`,
    )
    layer.name = layerName
    action.map.overlays[layerName] = {
      layer,
      isActive: false,
    }

    // add legend entry
    action.map.legendControl.addLegend({
      name: 'Custom targets',
      layer,
      elements: [
        {
          label: 'Max',
          html: '<img src="data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4wIHFxQAjNTK9QAAAEVJREFUOMvtz0ERACAMA8FCkw6+sIZvMHGPPoiAnctY+9wAp2EHC0osGHhh+8vTMKiCwcQLaTCre6GcNDh/YbdC02DB4AMG4AL4BWu3uQAAAABJRU5ErkJggg==" />',
        },
        {
          html: '<img src="data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4wIHFxQPHGvXZAAAAExJREFUOMvl1EERACAIBdEPgtlMahVbiSX2wIwE2HlwwNY+JXBipgkNZjobnEELQ7Sw4OAoeuXuQvyGiQudFvqlgx+urObBwIXG/sMH0G4OmEeLK1oAAAAASUVORK5CYII=" />',
        },
        {
          label: 'Min',
          html: '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4wIHFxQiWbSLEQAAAD5JREFUOMtj3PRg/38GKgIWViYGqgIWFkYmKhvINOgNHHleZqa2l5mZmKntQmZqxzLLSPPyCDSQBjllkHsZAKuyBCvkFTv8AAAAAElFTkSuQmCC" />',
        },
      ],
    })
  })
  return newState.asImmutable()
}

const reducers = (state = Map(), action) => {
  const _reducers = {
    account,
    config,
    inputs,
    map,
    page,
    analysis,
  }
  const newState = Map().asMutable()

  Object.keys(_reducers).forEach(key => {
    newState.set(key, _reducers[key](state.get(key, undefined), action))
  })

  if (action.type === ACTIONS.LOAD_STATE) {
    return loadState(newState, action)
  }
  return newState.asImmutable()
}

const store = createStore(reducers, composeEnhancer(applyMiddleware(thunk)))

store.dispatch(loadDatasets())
store.dispatch(loadConservationTargets())

ReactDOM.render(
  <Provider store={store}>
    <Layout
      store={store}
      header={<Header store={store} />}
      footer={<Footer />}
      sidebarContent={RePlan}
      extraSidebars={[
        {
          isOpen: false,
          label: {
            id: 'FilterSummarySidebarTab',
            text: 'Screen Settings',
          },
          component: FilterSummary,
        },
      ]}
    />
  </Provider>,
  document.getElementById('App'),
)
