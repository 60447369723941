import React from 'react'
import Page from '../../files/html/about-the-data-core.html'

const aboutTheData = { __html: Page }

export default class Help extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <div dangerouslySetInnerHTML={aboutTheData} />
  }
}
