import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Menu, Modal } from 'semantic-ui-react'
import axios from 'axios'
import { OrderedMap } from 'immutable'
import { connect } from 'react-redux'

import { updateResults } from '../../actions/analysis'
import { updateSelectedSites, resetInputs } from '../../actions/inputs'
import { updateIdentifyTarget } from '../../actions/map'
import { STEPS, updateLoadingStatus, updateActiveStep } from '../../actions/page'
import BaseHeader from '../BaseHeader'
import { LAYER_STYLES } from '../Map'
import LayoutContext from '../../context'
import Help from './Help'
import Share from './Share'
import Overview from './Overview'
import Login from '../Login'

class RePlanHeader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showOverview: !props.skipHome,
      showLinkDialog: false,
      linkHash: null,
    }
  }

  handleReset = () => {
    if (this.context.map) {
      this.context.map.flyTo([37.2478212, -121.11328125], 7)
      this.context.map.customRegionLayer.clearLayers()
      this.context.map.sitesLayer.clearLayers()
      Object.keys(this.context.map.overlays).forEach(overlay => {
        if (this.context.map.overlays[overlay].isActive) {
          this.context.map.toggleOverlay(overlay)
        }
      })
      this.context.map.overlays['Ecoregions (USDA Section)'].layer.setStyle(feature =>
        !feature.properties.value ? LAYER_STYLES.rcaUnavailable : LAYER_STYLES.rcaAvailable,
      )
      this.context.map.overlays['Watersheds (HUC 4)'].layer.setStyle(feature =>
        !feature.properties.value ? LAYER_STYLES.watersheds : LAYER_STYLES.watershedsRCA,
      )
      this.props.updateIdentifyTarget([])
      const selectedSites = OrderedMap({})
      this.context.map.updateSelectedSitesAttrs(selectedSites)
      this.props.updateSelectedSites(selectedSites)
      this.props.updateActiveStep(STEPS.REGION)
      this.props.resetInputs()
      this.props.updateResults({}, 'replace')
    }
  }

  handleGenerateLink = () => {
    this.props.updateLoadingStatus(true)
    const state = this.props.store.getState().toJS()
    const data = {
      config: {
        models: state.config.models,
        targets: state.config.targets,
      },
      inputs: state.inputs,
      customRegions: this.context.map.customRegionLayer.toGeoJSON().features,
    }
    axios
      .post(Urls['api:create-shared-link'](), data)
      .then(response => {
        this.setState({ showLinkDialog: true, linkHash: response.data.hash })
      })
      .catch(error => console.log(error))
      .finally(() => this.props.updateLoadingStatus(false))
  }

  render() {
    const rootUrl =
      window.location.href.indexOf('?s=') === -1
        ? window.location.href
        : window.location.href.substring(0, window.location.href.indexOf('?s='))
    return (
      <BaseHeader
        childrenLeft={
          <React.Fragment>
            <Menu.Item as="a" onClick={() => this.setState({ showOverview: true })}>
              <Icon name="home" size="large" />
              Tool Overview
            </Menu.Item>
            <Overview isVisible={this.state.showOverview} hideOverview={() => this.setState({ showOverview: false })} />

            <Modal
              trigger={
                <Menu.Item as="a">
                  <Icon name="question circle" size="large" />
                  About the Data
                </Menu.Item>
              }
              closeIcon
            >
              <Modal.Header>About the Data</Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <Help />
                </Modal.Description>
              </Modal.Content>
            </Modal>
          </React.Fragment>
        }
        childrenRight={
          <React.Fragment>
            <Menu.Item as="a" onClick={this.handleReset}>
              <Icon name="repeat" size="large" />
              Start over
            </Menu.Item>

            <Menu.Item as="a" onClick={this.handleGenerateLink}>
              <Icon name="share alternate" size="large" />
              Save URL for Future Use
            </Menu.Item>

            <Modal
              open={this.state.showLinkDialog}
              closeIcon
              onClose={() => this.setState({ showLinkDialog: false })}
              size="small"
            >
              <Modal.Content>
                <Share link={`${rootUrl}?s=${this.state.linkHash}`} />
              </Modal.Content>
            </Modal>
            <Menu.Item>
              <Login />
            </Menu.Item>
          </React.Fragment>
        }
      />
    )
  }
}

RePlanHeader.contextType = LayoutContext

RePlanHeader.propTypes = {
  // parent props
  store: PropTypes.object.isRequired,
  // redux props
  skipHome: PropTypes.bool.isRequired,
  // redux actions
  updateLoadingStatus: PropTypes.func.isRequired,
  updateIdentifyTarget: PropTypes.func.isRequired,
  updateActiveStep: PropTypes.func.isRequired,
  updateSelectedSites: PropTypes.func.isRequired,
  updateResults: PropTypes.func.isRequired,
  resetInputs: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  skipHome: state.getIn(['page', 'skipHome']),
})

export default connect(mapStateToProps, {
  updateLoadingStatus,
  updateIdentifyTarget,
  updateActiveStep,
  updateSelectedSites,
  updateResults,
  resetInputs,
})(RePlanHeader)
