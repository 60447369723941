import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Header, Segment } from 'semantic-ui-react'

import { GEOMETRY_SOURCES } from '../../actions/map'
import renderers from '../renderers'
import LayoutContext from '../../context'
import Section from './Section'

class Summary extends React.Component {
  constructor(props) {
    super(props)

    this.datasets = Object.values(props.datasets).reduce((allDatasets, categoryDatasets) => {
      categoryDatasets.forEach(dataset => {
        allDatasets[dataset.dataset_id] = dataset
      })
      return allDatasets
    }, {})
  }

  render() {
    let isFirstItem = true
    const geomsNum = this.context.map.sitesLayer.getLayers().length
    return (
      <Segment id="ScreenSummary" basic>
        <Header>Screen Settings</Header>
        {!Object.keys(this.props.queryParams).length && !this.props.minPolygonSize ? (
          <div>No screen setting is selected.</div>
        ) : null}
        {this.props.geometriesSource === GEOMETRY_SOURCES.filter
          ? `Identified ${geomsNum} site(s) matching the following screening criteria.`
          : null}
        {Object.entries(this.props.queryParams).map(([datasetId, values]) => {
          const section = (
            <Section
              key={datasetId}
              dataset={this.datasets[datasetId]}
              initValues={values}
              isStatic
              filter={renderers[datasetId].filter}
              isFirstItem={isFirstItem}
            />
          )
          isFirstItem = false
          return section
        })}
        {this.props.minPolygonSize ? <div>Min. polygon size: {this.props.minPolygonSize}</div> : null}
      </Segment>
    )
  }
}

Summary.contextType = LayoutContext

Summary.propTypes = {
  datasets: PropTypes.shape({}).isRequired,
  geometriesSource: PropTypes.string.isRequired,
  queryParams: PropTypes.shape({}).isRequired,
  minPolygonSize: PropTypes.number.isRequired,
}

const mapStateToProps = state => ({
  datasets: state.getIn(['config', 'datasets']).toJS(),
  geometriesSource: state.getIn(['map', 'geometriesSource']),
  queryParams: state.getIn(['inputs', 'filter', 'queryParams']).toJS(),
  minPolygonSize: state.getIn(['inputs', 'filter', 'minPolygonSize']),
})

export default connect(mapStateToProps)(Summary)
