import React from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, Tab, Header, List, Image } from 'semantic-ui-react'
import bannerLeft from '../../images/vertical-banner-left.png'
import bannerRight from '../../images/vertical-banner-right.png'
import dwr from '../../images/logo_dwr.png'
import cdfa from '../../images/logo_cdfa.png'

const panes = [
  {
    menuItem: 'IRCAD and RePlan Background',
    render: () => (
      <Tab.Pane>
        <p>
          The IRCAD initiative and the RePlan tool have been advanced through a partnership between the California
          Strategic Growth Council and the California Biodiversity Council (CBC). The IRCAD and Regional Conservation
          Assessment approach and methodology were developed by an interagency team consisting of the California Energy
          Commission, California Department of Fish and Wildlife, California Wildlife Conservation Board, California
          Department of Water Resources, Caltrans, California Department of Conservation, California Strategic Growth
          Council, US Fish and Wildlife Service, UC Davis and The Nature Conservancy. Initial support for the
          development of RePlan was provided by the California High Speed Rail Authority and the Wildlife Conservation
          Board. The Conservation Biology Institute developed and supports the RePlan platform.
        </p>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'RePlan Functions and Usage',
    render: () => (
      <Tab.Pane>
        <p>
          RePlan integrates multiple datasets with analytical and reporting functions to support regional planning
          processes. The geographic scope of analysis and reporting is selected by the user – with options to explore
          and analyze the entire state of California; one or more ecoregions, counties, or watersheds; or a customized
          user-identified region.
        </p>
        <p>The three main functions of this tool are:</p>
        <ol>
          <li>
            <p className="blue-darker">Landscape Exploration, Screening and Reporting.</p>
            <p>
              After selecting the region for analysis, the user can explore and/or screen this region using one or more
              environmental, land use, land designation, climate change, and ecosystem service factors. For landscape
              screening, the user can select subcategories and thresholds for each factor and identify the minimum site
              size to include in the landscape analysis. RePlan will screen the selected region to identify sites that
              meet all these criteria. The user can directly download the results of this screening process as a PDF
              report, or the results can become the basis for further exploration, analysis and reporting.
            </p>
            <br />
          </li>
          <li>
            <p className="blue-darker">Implementation and Application of Regional Conservation Assessments (RCAs).</p>
            <p>
              The RePlan application incorporates existing information on conservation values and opportunities at an
              ecoregional scale. The analysis and spatial representation of this information occurs through the
              development of a Regional Conservation Assessments (RCA). RePlan provides tools to represent and apply
              this RCA information for conservation and development planning decisions.
            </p>
            <p>
              The level of environmental information is richer for regions where RCAs have been completed. When working
              entirely or partially within one of these ecoregions with an RCA, the user can apply the detailed
              conservation information to screen, analyze and report on the landscape and selected sites. Where RCAs
              have been completed, the user has multiple options to represent the weighted conservation values of
              selected conservation targets (species, ecosystems and special habitats). These conservation value maps
              represent the predicted conservation values for those targets selected by the user. In summary, the
              conservation value maps represent:
            </p>
            <ul style={{ listStyle: 'none' }}>
              <li>i. Conservation values based on factors of rarity, threat, and endemism</li>
              <li>ii. Confidence weighted distribution maps of each conservation target</li>
              <li>
                iii. The expected number of targets based on their known and modeled distributions across the ecoregion
              </li>
              <li>iv. Models of weighted conservation values for the user-selected set of conservation targets</li>
            </ul>
            <br />
            <p>
              The user can select a pre-defined set of conservation targets, or define a custom set of targets for
              analysis. The selection options include:
            </p>
            <ul style={{ listStyle: 'none' }}>
              <li>i. All conservation targets</li>
              <li>ii. Conservation targets with legal status</li>
              <li>
                iii. Conservation targets whose conservation rank has been established through a range-wide scientific
                analysis
              </li>
              <li>iv. Any customized user-selected subset of conservation targets</li>
            </ul>
            <br />
            <p>
              These conservation value products identify broad areas that meet the defined conservation planning
              criteria. Additional field verification is required to verify presence and support finer level project
              planning and analysis. The detailed conservation value mapping functions can be applied to ecoregions
              where a Regional Conservation Assessment (RCA) has been completed. For other regions, the conservation
              value is mapped through the direct application of DFW datasets (e.g., CNDDB, ACE III, VegCamp and SWAP).
            </p>
            <p>
              This information addresses many of the RCA requirements in CDFW Regional Conservation Assessment
              Guidelines under AB 2087.
            </p>
            <br />
          </li>
          <li>
            <p className="blue-darker">Site Identification, Analysis, Comparison and Reporting.</p>
            <p>
              RePlan has many functions related to site identification, analysis, comparison and reporting. Site
              identification can occur in three ways:
            </p>
            <ul style={{ listStyle: 'none' }}>
              <li>i. Select a subset of the sites identified through the landscape screening process</li>
              <li>ii. Create new sites using the system drawing tools</li>
              <li>iii. Upload sites from external sources (shape files)</li>
            </ul>
            <br />
            <p>
              The user can select any subset of the screened or user defined sites for analysis, comparison, and
              reporting using any or all of the environmental, land designation and use, climate change and ecosystem
              service factors. These sites and associated datasets can be explored through the viewer, screen reports,
              and downloaded PDF and Excel reports.
            </p>
            <br />
          </li>
          <li>
            <p className="blue-darker">RePlan Tool Usage Steps.</p>
            <p>
              In order to efficiently explore the available information, complete their analyses and generate their
              planning reports, the user should follow the steps outlined below.
            </p>
            <ol>
              <li>
                <p>
                  <u>Define the area of interest.</u> The options include:
                </p>
                <ul style={{ listStyle: 'none' }}>
                  <li>i. Entire State of California</li>
                  <li>ii. Ecoregions – one or more</li>
                  <li>iii. Counties – one or more</li>
                  <li>iv. Watersheds – one or more</li>
                  <li>v. Custom region drawn by user</li>
                </ul>
                <br />
              </li>
              <li>
                <p>
                  <u>Landscape visualization and exploration.</u>
                </p>
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    i. For the State, County, Ecoregion, Watershed, and Custom Drawn Areas, this tool will support
                    landscape screening functions using environmental, land designation and use, climate change and
                    ecosystem service factors.
                  </li>
                  <li>
                    ii. For ecoregions and watersheds with completed Regional Conservation Assessments (RCA), RePlan can
                    enable the the user to visualize and explore the landscape using the menu of RCA conservation value
                    data that was produced for that region.
                  </li>
                  <li>iii. RePlan will identify sites to address all user-determined screening criteria and values.</li>
                  <li>
                    iv. The user can visualize, explore, and download the results from the landscape screening process.
                  </li>
                </ul>
                <br />
              </li>
              <li>
                <p>
                  <u>Site selection</u>, using the following 3 options:
                </p>
                <ul style={{ listStyle: 'none' }}>
                  <li>i. Select from landscape screening results</li>
                  <li>ii. Create new sites using the tools to draw points, lines or polygons</li>
                  <li>iii. Import points, lines or polygons from external shape files</li>
                </ul>
                <br />
              </li>
              <li>
                <p>
                  <u>Site analysis and reporting </u>
                </p>
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    i. Select a set of environmental, land designation and use, climate change and ecosystem service
                    factors datasets for site analysis and reporting.
                  </li>
                  <li>ii. RePlan provides the ability to:</li>
                  <ul style={{ listStyle: 'none' }}>
                    <li>
                      - View the analysis on-screen for one or more sites in relation to an individual planning factor
                    </li>
                    <li>
                      - Produce a PDF or Excel analysis report for one or more sites and a user selected set of planning
                      factors.
                    </li>
                  </ul>
                </ul>
                <br />
              </li>
            </ol>
          </li>
        </ol>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'RePlan Program Extensions',
    render: () => (
      <Tab.Pane>
        <p>
          This link provides a menu of RePlan programmatic applications for State of California Agencies and
          Departments. These applications utilize the full set of RePlan functionality along with specific datasets,
          analytical, and reporting capabilities that are customized for each program.
        </p>
        <p>
          RePlan includes the ability to address customized programmatic requirements where State agencies and our
          collaborators benefit from the efficient access and use of standardized datasets to prioritize their
          activities. RePlan’s developers have created program-specific applications for the Wildlife Conservation
          Board, the Department of Conservation, the Department of Food and Agriculture and the Department of Water
          Resources.
        </p>
        <p>
          The links below direct the user to these applications, which include additional datasets, exploration,
          analysis and reporting functions to address specific programmatic needs. Access to some of these programmatic
          applications requires administrative permission as they apply restricted datasets for internal departmental
          use.
        </p>
        <Grid style={{ maxWidth: '100%' }}>
          {window.PROPOSITIONS.length ? (
            <React.Fragment>
              <Grid.Column width={2}>
                <Image src={dwr} size="tiny" />
              </Grid.Column>
              <Grid.Column width={14}>
                <b>Department of Water Resources</b>
                <br />
                <p>
                  This RePlan extension for the DWR Riverine Stewardship Program’s ability helps evaluate the
                  conservation effectiveness of proposed projects and resource management sites. This application
                  integrates multiple aquatic resource datasets with a broad assortment of regional planning data,
                  analytical tools and reporting functions. NOAA/NMFS datasets represent critical habitat, distribution
                  and evolutionary units for Chinook, Steelhead and Sturgeon. Terrestrial and aquatic Regional
                  Conservation Assessments for the Great Valley Ecoregion and associated HUC 4 watersheds have been
                  completed for this application. An evaluation matrix for the Urban Streams Restoration Program and San
                  Joaquin Fish Population Enhancement Program analyzes all of this data to rate sites and projects.
                  NOAA/NMFS recommended restoration activities for each HUC 12 watersheds are included in all site
                  analysis reports.
                </p>
                <List bulleted>
                  {window.PROPOSITIONS.map(proposition => (
                    <List.Item style={{ fontSize: '15px', right: 'inherit' }} key={proposition.id}>
                      {proposition.name} (
                      <a href={Urls['propositions:details']('explore', proposition.slug)}>explore</a>
                      {(window.CONFIG.USER.isAdmin || window.CONFIG.USER.isStaff) && (
                        <span>
                          &nbsp;|&nbsp;
                          <a href={Urls['propositions:details']('review', proposition.slug)}>review</a>
                        </span>
                      )}
                      )
                    </List.Item>
                  ))}
                </List>
              </Grid.Column>
            </React.Fragment>
          ) : null}
          <Grid.Row>
            <Grid.Column width={2}>
              <Image src={cdfa} size="tiny" />
            </Grid.Column>
            <Grid.Column width={14}>
              <b>Department of Food and Agriculture</b>
              <br />
              <p>
                The CDFA Office of Environmental Farming and Innovation houses a number of Climate Smart Agriculture
                grant programs. The Healthy Soils Program (HSP) provides financial incentives to California growers and
                ranchers to implement conservation management practices that help sequester carbon, reduce atmospheric
                greenhouse gases, and improve overall soil health. The Alternative Manure Management Program (AMMP)
                provides financial assistance for the implementation of non-digester manure management practices on
                California dairy and livestock operations, which will result in reduced greenhouse gas emissions. These
                RePlan extensions provide easy access to the spatially explicit data that helps inform the HSP, and
                tools for prospective AMMP grant applicants to explore alternative manure management practices and
                potential GHG emission reductions, and develop proposed project diagrams.
              </p>
              <List bulleted>
                <List.Item style={{ fontSize: '15px', right: 'inherit' }}>
                  <a href="/cdfa/">CDFA HSP RePlan Tool</a>
                </List.Item>
                <List.Item style={{ fontSize: '15px', right: 'inherit' }}>
                  <a href="/ammp/">CDFA AMMP RePlan Tool</a>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Tab.Pane>
    ),
  },
]

class Overview extends React.Component {
  state = {
    started: false,
  }

  render() {
    return (
      <Grid id="OverviewContainer" relaxed padded style={{ display: this.props.isVisible ? '' : 'none' }}>
        <Grid.Row textAlign="center" verticalAlign="middle">
          <Grid.Column>
            <br />
            <Button
              primary
              content={this.state.started ? 'Continue' : 'Start'}
              onClick={() => {
                this.setState({ started: true })
                this.props.hideOverview()
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={14}>
            <Header as="h1" style={{ marginLeft: '243px' }}>
              Background and Overview.
            </Header>
            <Image
              src={bannerLeft}
              style={{
                width: '200px',
                marginRight: '43px',
              }}
              floated="left"
            />
            <Image
              src={bannerRight}
              style={{
                width: '225px',
                marginLeft: '43px',
              }}
              floated="right"
            />
            <p>
              RePlan is a core component of the California Strategic Growth Council’s (SGC) Integrated Regional
              Conservation and Development (IRCAD) initiative. This online tool supports the development and
              implementation of a sustainable and balanced vision for regional conservation and economic development.
            </p>
            <p>
              RePlan integrates the latest environmental, social, and economic data with analytic and reporting tools to
              allow users to identify optimal locations for implementing California’s conservation, resource management
              and development objectives. This tool helps to align regional planning and management activities in light
              of State and regional conservation, development, equity and resilience goals.
            </p>
            <p>
              Although this application is primarily focused to support conservation and development planning processes
              and programs across state agencies, it is broadly available for public use.
            </p>
            <p>Three tabs provide detailed information for RePlan users:</p>
            <ul style={{ listStyle: 'none' }}>
              <li>
                <strong>TAB 1</strong> provides background information about the team that developed and is supporting
                RePlan.
              </li>
              <li>
                <strong>TAB 2</strong> describes the different ways RePlan can be used to address regional planning
                objectives.
              </li>
              <li>
                <strong>TAB 3</strong> provides links to specific RePlan extensions that were developed to implement
                department-specific applications.
              </li>
            </ul>
            <p>
              Click the <strong>START</strong> button to begin using the tool. Return to this page at any point during a
              session by hitting the <strong>TOOL OVERVIEW</strong> button. Click the <strong>ABOUT THE DATA</strong>{' '}
              link on the top banner to access the full list and description of the datasets included in this tool. Use
              the links
              <strong> "Report a system problem"</strong> for technical issues and
              <strong> "Contact Program Manager"</strong> for help with a specific department application.
            </p>
            <p>Thank you for using RePlan!</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={14}>
            <Tab
              menu={{
                attached: true,
                pointing: true,
              }}
              panes={panes}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

Overview.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  hideOverview: PropTypes.func.isRequired,
}

export default Overview
