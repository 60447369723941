import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Icon, Header, List, Message, Modal, Segment } from 'semantic-ui-react'

import { updateIdentifyTarget } from '../../actions/map'
import LayoutContext from '../../context'
import { REGION_LAYER_NAMES } from '../../utils/rca'
import Custom from './Custom'

class RCA extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ecoregion: null,
    }
  }

  handleIdentify = (ecoregion, layerName, nextIdentifyTarget) => {
    Object.entries(this.context.map.overlays).forEach(([i, j]) => {
      if (i.indexOf(ecoregion) === 0 && i !== layerName && j.isActive) {
        this.context.map.toggleOverlay(i)
      }
    })
    if (
      (!this.context.map.overlays[layerName].isActive && nextIdentifyTarget.length) ||
      (this.context.map.overlays[layerName].isActive && !nextIdentifyTarget.length)
    ) {
      this.context.map.toggleOverlay(layerName)
    }
    this.props.updateIdentifyTarget(nextIdentifyTarget)
  }

  render() {
    const { availableRCAs, identifyTarget } = this.props
    return (
      <Segment id="RCA" basic>
        <Header as="h2" textAlign="center">
          Regional Conservation Assessment
          <Header.Subheader>Weighted Conservation Target Models</Header.Subheader>
        </Header>
        {Object.keys(availableRCAs).length ? (
          <React.Fragment>
            <Message positive>
              <p>
                Your selected region of interest intersects the following terrestrial ecoregions and aquatic basins with
                Regional Conservation Assessment (RCA) weighted conservation target models. For terrestrial models, you
                can explore any of the provided models or customize your own. By clicking the customize button.
              </p>
              <p>
                For all models, you can query targets listed for each cell or HUC12 watershed by clicking on the “I”
                icon next to the model name you wish to explore from the listing below or from the same listing under
                the Layers tab located to the right. Click on the map to identify what conservation target summaries may
                be present at that location.
              </p>
              <p>
                Click on Screen the Landscape or Draw or Import Sites for Analysis below to continue your analytical
                work.
              </p>
            </Message>
            <List divided verticalAlign="middle">
              {Object.entries(availableRCAs).map(([ecoregion, targets]) => (
                <List.Item key={ecoregion}>
                  {REGION_LAYER_NAMES[ecoregion].can_customize && (
                    <List.Content floated="right">
                      <Button
                        primary
                        size="mini"
                        content="Customize"
                        icon="sliders horizontal"
                        onClick={() => this.setState({ ecoregion })}
                      />
                    </List.Content>
                  )}
                  <List.Content>
                    <List.Header>{REGION_LAYER_NAMES[ecoregion].label}</List.Header>
                  </List.Content>
                  <List.Content>
                    <List.List>
                      {targets.map(target => {
                        const layerName = `${ecoregion}_${target.value}`
                        const isIdentifyActive =
                          identifyTarget.length && identifyTarget[0] === ecoregion && identifyTarget[1] === target.value
                        const nextIdentifyTarget = isIdentifyActive ? [] : [ecoregion, target.value]
                        return (
                          <List.Item key={layerName}>
                            <List.Content floated="right">
                              <a href="#" onClick={() => this.handleIdentify(ecoregion, layerName, nextIdentifyTarget)}>
                                <Icon.Group>
                                  <Icon
                                    name="square outline"
                                    size="large"
                                    color={isIdentifyActive ? 'orange' : 'blue'}
                                    title={
                                      isIdentifyActive
                                        ? 'Click this icon to turn off the Identify tool'
                                        : `Select this icon and then click a point on the map within the ${ecoregion} ecoregion to view detailed information about ${target.label.toLowerCase()} at that point.`
                                    }
                                  />
                                  <Icon
                                    name="info"
                                    color={isIdentifyActive ? 'orange' : 'blue'}
                                    title={
                                      isIdentifyActive
                                        ? 'Click this icon to turn off the Identify tool'
                                        : `Select this icon and then click a point on the map within the ${ecoregion} ecoregion to view detailed information about ${target.label.toLowerCase()} at that point.`
                                    }
                                  />
                                </Icon.Group>
                              </a>
                            </List.Content>
                            <List.Content>- {target.label}</List.Content>
                          </List.Item>
                        )
                      })}
                    </List.List>
                  </List.Content>
                </List.Item>
              ))}
            </List>

            <Modal closeIcon open={!!this.state.ecoregion} onClose={() => this.setState({ ecoregion: null })}>
              <Modal.Content>
                <Modal.Description>
                  <Custom
                    ecoregion={this.state.ecoregion}
                    postCustomizingCallback={() => this.setState({ ecoregion: null })}
                  />
                </Modal.Description>
              </Modal.Content>
            </Modal>
          </React.Fragment>
        ) : (
          <Message warning>
            <p>
              Your selected region of interest did not intersect any ecoregion with Regional Conservation Assessment
              (RCA) weighted conservation target models.
            </p>

            <p>
              Click on <i>Screen the Landscape</i> or <i>Draw or Import Sites for Analysis</i> below to continue your
              analytical work.
            </p>
          </Message>
        )}
      </Segment>
    )
  }
}

RCA.contextType = LayoutContext

RCA.propTypes = {
  // parent props
  availableRCAs: PropTypes.shape({}).isRequired,
  // redux props
  identifyTarget: PropTypes.arrayOf(PropTypes.string).isRequired,
  // redux actions
  updateIdentifyTarget: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  identifyTarget: state.getIn(['map', 'identifyTarget']).toJS(),
})

export default connect(mapStateToProps, { updateIdentifyTarget })(RCA)
