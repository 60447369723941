import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Form } from 'semantic-ui-react'

const ExistingBoundaries = ({ region, handleRegionChange }) => (
  <Form>
    <Form.Field inline>
      <Dropdown
        placeholder="Pick a Region"
        fluid
        selection
        options={[
          {
            value: 'california',
            text: 'State',
          },
          {
            value: 'ecoregion',
            text: 'Ecoregion (USDA Section)',
          },
          {
            value: 'county',
            text: 'County',
          },
          {
            value: 'watershed',
            text: 'Watershed (USGS HUC4)',
          },
          {
            value: 'custom',
            text: 'Custom',
          },
        ]}
        value={region}
        onChange={handleRegionChange}
      />
    </Form.Field>
  </Form>
)

ExistingBoundaries.propTypes = {
  // parent props
  region: PropTypes.string.isRequired,
  // parent actions
  handleRegionChange: PropTypes.func.isRequired,
}

export default ExistingBoundaries
