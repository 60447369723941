import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Dropdown, Form, Header, Input, Message, Segment } from 'semantic-ui-react'
import turfIntersect from '@turf/intersect'
import { polygon as turfPolygon } from '@turf/helpers'

import { mergeInputs } from '../../actions/inputs'
import { getEcoregionAndTarget } from '../../utils/rca'
import LayoutContext from '../../context'
import { CATEGORIES_LABELS, RCA_POLYGONS } from '../Map'
import renderers from '../renderers'
import Section from './Section'

class Screen extends React.Component {
  constructor(props) {
    super(props)

    this.categoriesDropdownOptions = []
    Object.entries(CATEGORIES_LABELS)
      .filter(([, label]) => label !== 'none')
      .map(([category, label]) =>
        this.categoriesDropdownOptions.push({
          value: category,
          text: label,
        }),
      )

    this.state = {
      activeCategory: 'env',
      minPolygonSize: props.minPolygonSize,
    }

    this.filters = {}
  }

  updateFilters = datasets => {
    Object.entries(datasets).forEach(([datasetId, values]) => {
      if (values) {
        this.filters[datasetId] = values
      } else if (this.filters[datasetId]) {
        delete this.filters[datasetId]
      }
    })
    this.props.mergeInputs('filter', {
      queryParams: this.filters,
    })
  }

  handleCategoryChange = (e, { value: category }) => {
    if (this.state.activeCategory !== category) {
      this.setState({ activeCategory: category })
    }
  }

  renderSections() {
    const sections = {}
    const intersectingRCAs = new Set()
    const customRegions = this.context.map.customRegionLayer.getLayers()
    Object.entries(RCA_POLYGONS).forEach(([rcaValue, rcaPolygon]) => {
      for (let i = customRegions.length; i > 0; i -= 1) {
        const layer = customRegions[i - 1].toGeoJSON()
        let intersects = false
        if (layer.geometry.type === 'MultiPolygon') {
          for (let j = layer.geometry.coordinates; j > 0; j -= 1) {
            const selectedRegion = turfPolygon(layer.geometry.coordinates[j])
            if (turfIntersect(selectedRegion, rcaPolygon)) {
              intersectingRCAs.add(rcaValue)
              intersects = true
              break
            }
          }
        } else {
          const selectedRegion = turfPolygon(layer.geometry.coordinates)
          if (turfIntersect(selectedRegion, rcaPolygon)) {
            intersectingRCAs.add(rcaValue)
            intersects = true
          }
        }
        if (intersects) {
          break
        }
      }
    })
    const filters = {}
    Object.entries(this.props.datasets).forEach(([category, datasets]) => {
      sections[category] = []
      let isFirstItem = true
      datasets.forEach(dataset => {
        if (dataset.filter) {
          let renderer
          if (dataset.dataset_id.indexOf('rca_') === 0) {
            const [ecoregion] = getEcoregionAndTarget(dataset.dataset_id)
            if (intersectingRCAs.has(ecoregion)) {
              renderer = renderers.rca.filter
            } else {
              return
            }
          } else {
            renderer = renderers[dataset.dataset_id].filter
          }
          filters[dataset.dataset_id] = this.filters[dataset.dataset_id] || renderer.defaultValue
          sections[category].push(
            <Section
              key={dataset.dataset_id}
              isHidden={this.state.activeCategory !== category}
              dataset={dataset}
              filter={renderer}
              updateFilters={this.updateFilters}
              isFirstItem={isFirstItem}
            />,
          )
          isFirstItem = false
        }
      })
    })
    return sections
  }

  render() {
    const sections = this.renderSections()

    return (
      <Segment basic>
        <Header as="h2" textAlign="center">
          Screen the Landscape
        </Header>

        <Message positive size="small">
          <p>
            This tool identifies locations (1km grids) within your region of interest that meet specific screening
            criteria you define from a list of data layers organized under four main categories below. Select screening
            criteria under each category of interest and then click <i>Find Areas</i> to see the results. Modify
            screening criteria as many times as necessary to refine your results. Summary of all screen settings are
            loaded to the Screen Settings tab as they are adjusted for easy review.
          </p>
          In order to select specific sites for further analysis, click on the <i>Select Sites for Analysis</i> below.
          If you wish to keep a record of the screen settings and the results, click <i>Download Results</i> for a PDF
          copy. You can also save all settings by clicking on the <i>Save URL for Future Use</i> in the upper right-hand
          corner of the screen. This provides a simple way for you to return to where you left off at any time in the
          future or to share your settings with others.
        </Message>

        <Form>
          <Header size="small">Select Screening Criteria</Header>

          <Header size="tiny">Select a Category:</Header>
          <Dropdown
            placeholder="Select Category"
            fluid
            search
            floating
            selection
            options={this.categoriesDropdownOptions}
            value={this.state.activeCategory}
            onChange={this.handleCategoryChange}
          />

          {this.state.activeCategory === 'env' ? (
            <Message warning visible>
              Adjust handles on the sliders below. Values between the two handles will be included in the results.
            </Message>
          ) : null}

          <Segment style={{ height: '88%' }}>
            <Container>{Object.values(sections)}</Container>
          </Segment>

          <Header size="small">Set minimum polygon size</Header>
          <Form.Field>
            <small className="fieldHint">Removes small areas from the results (optional)</small>
            <Input
              value={this.state.minPolygonSize}
              type="number"
              label="acres"
              labelPosition="right"
              min={0}
              onChange={(e, props) =>
                this.setState({ minPolygonSize: parseFloat(props.value || 0) }, () => {
                  this.props.mergeInputs('filter', {
                    minPolygonSize: this.state.minPolygonSize,
                  })
                })
              }
            />
          </Form.Field>
        </Form>
      </Segment>
    )
  }
}

Screen.contextType = LayoutContext

Screen.propTypes = {
  // redux props
  datasets: PropTypes.shape({}).isRequired,
  minPolygonSize: PropTypes.number.isRequired,
  // redux actions
  mergeInputs: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  datasets: state.getIn(['config', 'datasets']).toJS(),
  minPolygonSize: state.getIn(['inputs', 'filter', 'minPolygonSize']),
})

export default connect(mapStateToProps, { mergeInputs })(Screen)
