import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Accordion, Button, Checkbox, Divider, Header, Icon, List, Menu, Segment } from 'semantic-ui-react'

import { SPECIES_INFO, SPECIAL_HABITATS, ECOSYSTEMS } from '../../species'
import { createCustomModel } from '../../actions/config'
import { toTitleCase } from '../../utils/formatters'
import LayoutContext from '../../context'
import { REGION_LAYER_NAMES } from '../../utils/rca'

class Custom extends React.Component {
  constructor(props) {
    super(props)

    this.ecosystems = ECOSYSTEMS[props.ecoregion]
    this.habitats = SPECIAL_HABITATS[props.ecoregion]
    this.speciesByTaxon = new Map()
    this.taxon = []
    const allSpecies = Object.entries(SPECIES_INFO[props.ecoregion])
    allSpecies.sort(([, sp1], [, sp2]) => (sp1.common_name.toLowerCase() > sp2.common_name.toLowerCase() ? 1 : -1))
    allSpecies.forEach(([id, species]) => {
      if (this.speciesByTaxon.has(species.taxon_group)) {
        this.speciesByTaxon.get(species.taxon_group).set(id, species)
      } else {
        const taxonGroup = new Map()
        taxonGroup.set(id, species)
        this.speciesByTaxon.set(species.taxon_group, taxonGroup)
        this.taxon.push(species.taxon_group)
      }
    })
    this.taxon.sort()

    const species = {}
    this.speciesByTaxon.forEach((speciesGroups, taxon) => {
      species[taxon] = Object.keys(speciesGroups).reduce((all, sp) => {
        all[sp] = false
        return all
      }, {})
    })

    this.state = {
      itemsVisibility: {},
      species,
      ecosystems: Object.keys(this.ecosystems).reduce((allEcosystems, ecosystem) => {
        allEcosystems[ecosystem] = false
        return allEcosystems
      }, {}),
      habitats: Object.keys(this.habitats).reduce((allHabitats, habitat) => {
        allHabitats[habitat] = false
        return allHabitats
      }, {}),
      allEcosystemsCheckbox: null,
      allHabitatsCheckbox: null,
    }
  }

  toggleItemVisibility(item) {
    this.setState(state => {
      state.itemsVisibility[item] = !state.itemsVisibility[item]
      return state
    })
  }

  handleAllEcosystemsToggle(isChecked) {
    this.setState(state => {
      state.ecosystems = Object.keys(this.ecosystems).reduce((allEcosystems, ecosystem) => {
        allEcosystems[ecosystem] = isChecked
        return allEcosystems
      }, {})
      state.allEcosystemsCheckbox = isChecked ? 'all' : null
      return state
    })
  }

  handleEcosystemToggle(featureId, isChecked) {
    this.setState(state => {
      state.ecosystems[featureId] = isChecked
      const allEcosystemsSet = new Set(Object.values(state.ecosystems))
      if (allEcosystemsSet.size === 2) {
        state.allEcosystemsCheckbox = 'some'
      } else if (allEcosystemsSet.has(true)) {
        state.allHabitatsCheckbox = 'all'
      } else {
        state.allHabitatsCheckbox = null
      }
      return state
    })
  }

  handleAllHabitatsToggle(isChecked) {
    this.setState(state => {
      state.habitats = Object.keys(this.habitats).reduce((allHabitats, habitat) => {
        allHabitats[habitat] = isChecked
        return allHabitats
      }, {})
      state.allHabitatsCheckbox = isChecked ? 'all' : null
      return state
    })
  }

  handleHabitatToggle(featureId, isChecked) {
    this.setState(state => {
      state.habitats[featureId] = isChecked
      const allHabitatsSet = new Set(Object.values(state.habitats))
      if (allHabitatsSet.size === 2) {
        state.allHabitatsCheckbox = 'some'
      } else if (allHabitatsSet.has(true)) {
        state.allHabitatsCheckbox = 'all'
      } else {
        state.allHabitatsCheckbox = null
      }
      return state
    })
  }

  handleAllTaxonsToggle(isChecked) {
    this.setState(state => {
      ;[...this.speciesByTaxon.entries()].forEach(([taxon, species]) => {
        state.species[taxon] = [...species.keys()].reduce((all, sp) => {
          all[sp] = isChecked
          return all
        }, {})
      })
      return state
    })
  }

  handleTaxonToggle(taxon, isChecked) {
    const species = [...this.speciesByTaxon.get(taxon).keys()].reduce((all, sp) => {
      all[sp] = isChecked
      return all
    }, {})
    this.setState(state => {
      state.species[taxon] = species
      return state
    })
  }

  handleSpeciesToggle(taxon, speciesId, isChecked) {
    this.setState(state => {
      state.species[taxon][speciesId] = isChecked
      return state
    })
  }

  handleModelProcessing() {
    const selectedEcosystems = Object.entries(this.state.ecosystems)
      .filter(([, isSelected]) => isSelected)
      .map(([ecosystem]) => ecosystem)

    const selectedSpecies = []
    Object.values(this.state.species).forEach(speciesGroup =>
      Object.entries(speciesGroup).forEach(([species, isSelected]) => {
        if (isSelected) {
          selectedSpecies.push(toTitleCase(species))
        }
      }),
    )
    this.props.createCustomModel(
      {
        region: this.props.ecoregion,
        ecosystems: selectedEcosystems,
        species: selectedSpecies,
        special_habitats: Object.entries(this.state.habitats)
          .filter(([, v]) => v)
          .map(([k]) => k),
      },
      this.context.map,
    )
    this.props.postCustomizingCallback()
  }

  renderTaxonSpecies = taxon => {
    return [...this.speciesByTaxon.get(taxon).entries()].map(([id, sp]) => (
      <List.Item key={id}>
        <Checkbox
          label={sp.common_name}
          disabled={this.props.isLoading}
          checked={this.state.species[taxon] && this.state.species[taxon][id]}
          onChange={(e, props) => this.handleSpeciesToggle(taxon, id, props.checked)}
        />
      </List.Item>
    ))
  }

  renderTaxons = () => {
    const allTaxonsSet = new Set()
    const taxonsAccordions = this.taxon.map(taxon => {
      let taxonCheckbox
      if (this.state.species[taxon]) {
        const taxonSet = new Set(Object.values(this.state.species[taxon]))
        if (taxonSet.size === 2) {
          taxonCheckbox = 'some'
          allTaxonsSet.add(true)
          allTaxonsSet.add(false)
        } else if (taxonSet.has(true)) {
          taxonCheckbox = 'all'
          allTaxonsSet.add(true)
        } else {
          allTaxonsSet.add(false)
        }
      }
      return (
        <React.Fragment key={taxon}>
          <Accordion.Title active={this.state.itemsVisibility[taxon]}>
            <Header size="small" style={{ display: 'inline-block' }}>
              <Icon name="dropdown" onClick={() => !this.props.isLoading && this.toggleItemVisibility(taxon)} />
              <Checkbox
                label={taxon}
                disabled={this.props.isLoading}
                indeterminate={taxonCheckbox === 'some'}
                checked={taxonCheckbox === 'all'}
                onChange={(e, props) => this.handleTaxonToggle(taxon, props.checked)}
              />
            </Header>
          </Accordion.Title>
          <Accordion.Content active={this.state.itemsVisibility[taxon]} style={{ paddingLeft: 30 }}>
            <List>{this.renderTaxonSpecies(taxon)}</List>
          </Accordion.Content>
        </React.Fragment>
      )
    })
    let allTaxonsCheckbox
    if (allTaxonsSet.size === 2) {
      allTaxonsCheckbox = 'some'
    } else if (allTaxonsSet.has(true)) {
      allTaxonsCheckbox = 'all'
    }
    return [allTaxonsCheckbox, taxonsAccordions]
  }

  render() {
    const [allTaxonsCheckbox, taxonsAccordions] = this.renderTaxons()
    return (
      <Segment basic disabled={this.props.isLoading}>
        <Menu className="borderless" borderless>
          <Menu.Item>
            <Header>Select Custom Targets for {REGION_LAYER_NAMES[this.props.ecoregion].label}</Header>
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>
              <Button
                content="Cancel"
                disabled={this.props.isLoading}
                onClick={() => this.props.postCustomizingCallback()}
              />
            </Menu.Item>
            <Menu.Item>
              <Button
                content="Process Model"
                primary
                disabled={this.props.isLoading}
                onClick={() => this.handleModelProcessing()}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        <Accordion exclusive={false}>
          <Accordion.Title active={this.state.itemsVisibility.species}>
            <Header size="small" style={{ display: 'inline-block' }}>
              <Icon
                name="dropdown"
                disabled={this.props.isLoading}
                onClick={() => !this.props.isLoading && this.toggleItemVisibility('species')}
              />
              <Checkbox
                label="Species"
                indeterminate={allTaxonsCheckbox === 'some'}
                checked={allTaxonsCheckbox === 'all'}
                disabled={this.props.isLoading}
                onChange={(e, props) => this.handleAllTaxonsToggle(props.checked)}
              />
            </Header>
          </Accordion.Title>
          <Accordion.Content active={this.state.itemsVisibility.species} style={{ paddingLeft: 30 }}>
            {taxonsAccordions}
          </Accordion.Content>

          <Divider />

          <Accordion.Title active={this.state.itemsVisibility.ecosystems}>
            <Header size="small" style={{ display: 'inline-block' }}>
              <Icon
                name="dropdown"
                disabled={this.props.isLoading}
                onClick={() => !this.props.isLoading && this.toggleItemVisibility('ecosystems')}
              />
              <Checkbox
                label="Ecosystems"
                indeterminate={this.state.allEcosystemsCheckbox === 'some'}
                checked={this.state.allEcosystemsCheckbox === 'all'}
                disabled={this.props.isLoading}
                onChange={(e, props) => this.handleAllEcosystemsToggle(props.checked)}
              />
            </Header>
          </Accordion.Title>
          <Accordion.Content active={this.state.itemsVisibility.ecosystems} style={{ paddingLeft: 30 }}>
            <List>
              {Object.entries(this.ecosystems).map(([id, label]) => (
                <List.Item key={id}>
                  <Checkbox
                    label={label}
                    disabled={this.props.isLoading}
                    checked={this.state.ecosystems[id]}
                    onChange={(e, props) => this.handleEcosystemToggle(id, props.checked)}
                  />
                </List.Item>
              ))}
            </List>
          </Accordion.Content>

          <Divider />

          <Accordion.Title active={this.state.itemsVisibility.habitats}>
            <Header size="small" style={{ display: 'inline-block' }}>
              <Icon
                name="dropdown"
                disabled={this.props.isLoading}
                onClick={() => !this.props.isLoading && this.toggleItemVisibility('habitats')}
              />
              <Checkbox
                label="Special Habitats"
                indeterminate={this.state.allHabitatsCheckbox === 'some'}
                checked={this.state.allHabitatsCheckbox === 'all'}
                disabled={this.props.isLoading}
                onChange={(e, props) => this.handleAllHabitatsToggle(props.checked)}
              />
            </Header>
          </Accordion.Title>
          <Accordion.Content active={this.state.itemsVisibility.habitats} style={{ paddingLeft: 30 }}>
            <List>
              {Object.entries(this.habitats).map(([id, label]) => (
                <List.Item key={id}>
                  <Checkbox
                    label={label}
                    disabled={this.props.isLoading}
                    checked={this.state.habitats[id]}
                    onChange={(e, props) => this.handleHabitatToggle(id, props.checked)}
                  />
                </List.Item>
              ))}
            </List>
          </Accordion.Content>
        </Accordion>
      </Segment>
    )
  }
}

Custom.contextType = LayoutContext

Custom.propTypes = {
  // parent props
  ecoregion: PropTypes.string.isRequired,
  // parent actions
  postCustomizingCallback: PropTypes.func.isRequired,
  // redux props
  isLoading: PropTypes.bool.isRequired,
  // redux actions
  createCustomModel: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  isLoading: state.getIn(['page', 'isLoading']),
})

export default connect(mapStateToProps, { createCustomModel })(Custom)
