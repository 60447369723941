import axios from 'axios'

import { fetchQueryResults } from '../utils/io'
import { LAYER_STYLES } from '../containers/Map'
import { GEOMETRY_SOURCES, updateGeometriesSource } from './map'
import { updateLoadingStatus, updateQueryStatus } from './page'

const BOUNDARIES = {
  ecoregion: 'Ecoregions (USDA Section)',
  county: 'Counties',
  watershed: 'Watersheds (HUC 4)',
}

function startFilter(map, datasets, min_result_size = 0) {
  return dispatch => {
    dispatch(updateLoadingStatus(true))
    dispatch(updateQueryStatus(true))

    axios
      .post(Urls['api:query'](), {
        action: 'filter',
        datasets,
        bounds: map.customRegionLayer.toGeoJSON().features,
        min_result_size,
      })
      .then(response => {
        fetchQueryResults(response.data.task_ids, (status, results) => {
          if (status !== 'error') {
            dispatch(updateGeometriesSource(GEOMETRY_SOURCES.filter))
            map.addToSitesLayer(results.features)
            map.fitBounds(map.sitesLayer.getBounds())
            map.customRegionLayer.setStyle(LAYER_STYLES.customRegion)
            Object.values(BOUNDARIES).forEach(label => {
              if (map.overlays[label] && map.overlays[label].isActive) {
                map.toggleOverlay(label)
              }
            })
          }
          dispatch(updateLoadingStatus(false))
        })
      })
      .catch(() => dispatch(dispatch(updateLoadingStatus(false))))
  }
}

function downloadFilterSummary(data) {
  return dispatch => {
    const submitRequest = container => {
      axios
        .post(
          Urls['api:report-filter'](),
          {
            html: container.innerHTML,
            ...data,
          },
          {
            responseType: 'blob',
            headers: { 'Content-Type': 'application/json' },
          },
        )
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const reader = new FileReader()
          const filename = `screen_summary_${new Date().toLocaleDateString()}.pdf`
          reader.addEventListener('loadend', e => {
            if (navigator.msSaveBlob) {
              // IE11 & Edge
              navigator.msSaveBlob(e.target.result, filename)
            } else {
              const node = document.createElement('a')
              node.setAttribute('href', e.target.result)
              node.setAttribute('download', filename)
              document.body.appendChild(node)
              node.click()
              document.body.removeChild(node)
            }

            dispatch(updateLoadingStatus(false))
          })
          reader.readAsDataURL(blob)
        })
        .catch(() => {
          dispatch(updateLoadingStatus(false))
        })
    }

    const download = () => {
      const container = document.getElementById('ScreenSummary')
      if (container) {
        submitRequest(container)
      } else {
        setTimeout(download, 500)
      }
    }
    download()
  }
}

export { startFilter, downloadFilterSummary, BOUNDARIES }
