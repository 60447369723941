import React from 'react'
import { List } from 'immutable'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Divider, Header, Icon, Segment } from 'semantic-ui-react'

import LayoutContext from '../../context'
import { updateModal } from '../../actions/page'

class Section extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasDefaultValues: props.initValues.length === 0,
    }
  }

  updateFilters = values => {
    if (!this.props.isStatic) {
      this.setState(
        { hasDefaultValues: values[this.props.dataset.dataset_id] === this.props.filter.defaultValue },
        () => this.props.updateFilters(values),
      )
    }
  }

  render() {
    const Filter = this.props.filter

    return (
      <React.Fragment>
        {this.props.isFirstItem ? null : <Divider className={this.props.isHidden ? 'hidden' : null} />}
        <Segment
          className={`${this.props.isHidden ? 'hidden' : ''} ${this.props.isStatic ? 'static' : ''}`}
          basic
          style={{ padding: '15px 0' }}
        >
          <div style={{ marginBottom: 20 }}>
            <Header floated="left" size="tiny">
              {this.props.dataset.label}
              {this.props.filter.ignoreReset || this.props.isStatic ? null : (
                <a href="#" onClick={() => this.setState({ hasDefaultValues: true })}>
                  <Icon name="remove circle" color="red" className={this.state.hasDefaultValues ? 'hidden' : null} />
                </a>
              )}
            </Header>
            {this.props.dataset.disclaimer ? (
              <div>
                <br />
                <a
                  style={{ color: 'orange', cursor: 'pointer' }}
                  onClick={() => this.props.updateModal({ active: true, text: this.props.dataset.disclaimer })}
                >
                  {`DISCLAIMER: ${this.props.dataset.disclaimer.substring(0, 101)} ...[read more]`}
                </a>
              </div>
            ) : null}
            {!this.props.isStatic &&
              this.props.dataset.overlays.map(overlay => (
                <Icon
                  key={overlay.name}
                  link
                  name="map"
                  color={
                    this.context.map.overlays[overlay.name] && this.context.map.overlays[overlay.name].isActive
                      ? 'orange'
                      : 'grey'
                  }
                  style={{ float: 'right' }}
                  title="Toggle map overlay"
                  onClick={() => this.context.map.toggleOverlay(overlay.name)}
                />
              ))}
            {!this.props.isStatic && this.props.dataset.url ? (
              <a href={this.props.dataset.url} target="_blank" rel="noopener noreferrer">
                <Icon
                  name="external"
                  title="View in Data Basin"
                  style={{
                    float: 'right',
                    marginRight: 10,
                  }}
                />
              </a>
            ) : null}
          </div>
          <Filter
            initValues={this.props.initValues}
            isStatic={this.props.isStatic}
            dataset={this.props.dataset}
            onChange={this.updateFilters}
            useDefaultValues={this.state.hasDefaultValues}
          />
        </Segment>
      </React.Fragment>
    )
  }
}

Section.contextType = LayoutContext

Section.propTypes = {
  dataset: PropTypes.shape({
    dataset_id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    url: PropTypes.string,
    overlays: PropTypes.arrayOf(PropTypes.shape({})),
    disclaimer: PropTypes.string,
  }).isRequired,
  filter: PropTypes.func.isRequired,
  isHidden: PropTypes.bool,
  updateFilters: PropTypes.func,
  isFirstItem: PropTypes.bool.isRequired,
  isStatic: PropTypes.bool,
  initValues: PropTypes.arrayOf(PropTypes.array).isRequired,
  updateModal: PropTypes.func.isRequired,
}

Section.defaultProps = {
  updateFilters: () => {},
  isHidden: false,
  isStatic: false,
}

const mapStateToProps = (state, props) => ({
  initValues: state.getIn(['inputs', 'filter', 'queryParams', props.dataset.dataset_id], List()).toJS(),
})

export default connect(mapStateToProps, { updateModal })(Section)
