import React from 'react'
import L from 'leaflet'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Confirm, Header, Message, Segment } from 'semantic-ui-react'

import { updateResults } from '../../actions/analysis'
import { BOUNDARIES } from '../../actions/filter'
import { updateRegions } from '../../actions/inputs'
import { updateGeometriesSource } from '../../actions/map'
import LayoutContext from '../../context'
import { LAYER_STYLES } from '../Map'
import ExistingBoundaries from './ExistingBoundaries'

class Regions extends React.Component {
  static contextType = LayoutContext

  constructor(props) {
    super(props)

    this.state = {
      showDiscardSitesDialog: false,
      selectedRegion: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.prepareBoundaries(nextProps.region)
    this.context.map.customRegionLayer.setStyle(LAYER_STYLES.customRegion)
  }

  prepareBoundaries = region => {
    Object.entries(BOUNDARIES).forEach(([value, label]) => {
      if (this.context.map.overlays[label] && !this.context.map.overlays[label].isActive && value === region) {
        this.context.map.toggleOverlay(label)
      } else if (this.context.map.overlays[label] && this.context.map.overlays[label].isActive && value !== region) {
        this.context.map.toggleOverlay(label)
      }
    })
  }

  changeRegion = region => {
    this.context.map.customRegionLayer.clearLayers()
    this.context.map.sitesLayer.clearLayers()
    this.prepareBoundaries(region)
    if (region === 'custom') {
      this.context.map.drawControl._toolbars.draw._modes.polygon.handler.enable()
    } else {
      this.context.map.drawControl._toolbars.draw._modes.polygon.handler.disable()
    }
    this.props.updateRegions(
      {
        region,
        hasCustomRegion: false,
        ecoregions: {},
        counties: {},
        watersheds: {},
      },
      this.context.map.customRegionLayer,
    )
  }

  handleConfirmedChangeRegion = () => {
    this.context.map.customRegionLayer.clearLayers()
    this.props.updateGeometriesSource('')
    this.props.updateResults({}, 'replace')
    this.changeRegion(this.state.selectedRegion || 'california')

    this.setState({
      selectedRegion: null,
      showDiscardSitesDialog: false,
    })
  }

  handleRegionChange = (event, { value: region }) => {
    if (region !== this.props.region) {
      if (this.props.hasSelectedRegions) {
        this.setState({ showDiscardSitesDialog: true, selectedRegion: region })
      } else {
        this.changeRegion(region)
      }
    }
  }

  render() {
    return (
      <Segment basic>
        <Header as='h2' textAlign='center'>
          Select Regions of Interest
        </Header>

        <Message positive size='small'>
          <p>
            Choose a category from the dropdown list below. You may select: (1) the entire State of California, (2) one
            or more ecoregions, counties, or watersheds, or (3) a single custom polygon drawn on the map. For the
            ecoregion, county, and watershed categories, you will need to make your selection from the map by clicking
            one or more features. To deselect any chosen feature, just click on it again.
          </p>
          <p>
            Click Next Step at the bottom of the page to zoom into your region of interest where you can explore the
            preloaded data layers listed under the Layers tab and gain access to analytical functions of the tool.
          </p>
        </Message>

        <ExistingBoundaries region={this.props.region} handleRegionChange={this.handleRegionChange} />

        <Confirm
          open={this.state.showDiscardSitesDialog}
          content='Changing region type will remove the selected ones. Continue?'
          onCancel={
            () => this.setState(
              {
                showDiscardSitesDialog: false,
                selectedRegion: null,
              },
            )
          }
          onConfirm={this.handleConfirmedChangeRegion}
        />
      </Segment>
    )
  }
}

Regions.propTypes = {
  // redux props
  region: PropTypes.string.isRequired,
  hasSelectedRegions: PropTypes.bool.isRequired,
  // redux actions
  updateGeometriesSource: PropTypes.func.isRequired,
  updateRegions: PropTypes.func.isRequired,
  updateResults: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  region: state.getIn(['inputs', 'region', 'region']),
  hasSelectedRegions: !!(
    state.getIn(['inputs', 'region', 'hasCustomRegion']) ||
    state.getIn(['inputs', 'region', 'ecoregions']).size ||
    state.getIn(['inputs', 'region', 'counties']).size ||
    state.getIn(['inputs', 'region', 'watersheds']).size
  ),
})

export default connect(mapStateToProps, {
  updateRegions,
  updateGeometriesSource,
  updateResults,
})(Regions)
